<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.06136 5.452C5.67084 5.06143 5.03767 5.06143 4.64715 5.452C4.25663 5.84257 4.25663 6.47581 4.64715 6.86638L5.42515 7.64447L4.64715 8.42256C4.25663 8.81313 4.25663 9.44637 4.64715 9.83693C5.03768 10.2275 5.67084 10.2275 6.06136 9.83693L7.54647 8.35166C7.93699 7.96109 7.93699 7.32785 7.54647 6.93728L6.06136 5.452Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.81039 14.5857C9.81066 14.5857 9.81012 14.5857 9.81039 14.5857C9.81012 14.5857 9.80903 14.5857 9.80876 14.5857L3.12647 14.5857C1.75399 14.5857 0.641368 13.4729 0.641368 12.1003L0.641366 3.18864C0.641366 1.81599 1.75399 0.703245 3.12647 0.703245L12.0371 0.703243C13.4096 0.703243 14.5222 1.81599 14.5222 3.18864L14.5222 12.1003C14.5222 13.4729 13.4096 14.5857 12.0371 14.5857L9.81039 14.5857ZM2.64137 3.18864C2.64137 2.92069 2.85856 2.70348 3.12647 2.70348L8.80957 2.70347L8.80958 12.5855L3.12647 12.5855C2.85856 12.5855 2.64137 12.3682 2.64137 12.1003L2.64137 3.18864ZM10.8096 2.70347L12.0371 2.70347C12.305 2.70347 12.5222 2.92069 12.5222 3.18864L12.5222 12.1003C12.5222 12.3682 12.305 12.5855 12.0371 12.5855L10.8096 12.5855L10.8096 2.70347Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExpandedRight'
}
</script>
